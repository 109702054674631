import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import BlueBannerImmediateAction from './BlueBannerImmediateAction';
import WhoIsAffected from './WhoIsAffected';
import WhatHappened from './WhatHappened';
import WritePerfectTicket from './WritePerfectTicket';
import AddAttachments from './AddAttachments';
import DevAffected from './DevAffected';
import AdditionalInfoBtns from './AdditionalInfoBtns';
import BusinessCriticalTicketInfor from './BusinessCriticalTicketsInfo';
import PersonPicker from './PersonPicker';
import SupportBar from '../SupportBar/SupportBar';
import { Grid } from '@mui/material';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';
import { getSapKeyUser } from '../../api/ticketsEndpoints';
import ButtonsSpeedDial from '../ButtonsSpeedDial/ButtonsSpeedDial';
import { TooltipContextProvider } from '../../context/TooltipContext';
import { createUniqueID } from '../../utils/UniqueId';
import LoaderTicketCreation from '../Confirmations/LoaderTicketCreation';
import EmojisNotAllowedMessage from './EmojisNotAllowedMessage'
import axiosInstance from '../../utils/axiosInstance';

const ConfigDOMPurify = require('../../config/DOMP');

const Ticket = (props) => {
  const [openChatFromTicket, setOpenChatFromTicket] = useState(false);
  const [openCallFromTicket, setOpenCallFromTicket] = useState(false);
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
  const [isSapKeyUser, setIsSapUserKey] = useState(false);
  const [userInfoSuppTicket, setUserInfoSuppTicket] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [openPersonPicker, setOpenPersonPicker] = useState(false);
  const [userPhones, setUserphones] = useState([]);
  const [userDevices, setUserDevices] = useState([]);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitError, setSubmitError] = useState(null);
  const [ticketSuppData, setTicketSuppData] = useState({
    Customer_ID: '',
    Submitter_ID: '',
    Contact: '',
    Assigned_Group: 'ITSD - IT Service Desk',
    Summary: '',
    Notes: '',
    ServiceCI: 'ITSD_DEFAULTSERVICE',
    Impact: 4000,
    Incident_Type: 'User Service Request',
    Urgency: 4000, // 4-Low (4000), 3-Medium (4000)
    Priority: 3, // 3 => low, 2 => medium
    Reported_Date: new Date().getTime(),
    Submit_Date: new Date().getTime(),
    Priority_Weight: 0, // 0 => 4, 10 => 3, 15 => 2, 1 => 20
    ServiceCI_ReconID: '',
    Reported_Source: 4200,
    Status: 'New',
    Categorization_Tier_1: 'Request'
  });
  const [ticketDetails, setTicketDetails] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [fileList, setFileList] = useState([]);
  const [incidentId, setIncidentId] = useState('');
  const [worklogDet, setWorklogDet] = useState({
    incident_number: '',
    work_log_type: 15000,
    notes: 'user attachments',
    work_log_submitter: '',
    attach1_name: null,
    attach1_base64: null,
    attach1_size: null,
    attach2_name: null,
    attach2_base64: null,
    attach2_size: null,
    attach3_name: null,
    attach3_base64: null,
    attach3_size: null,
    linked_document: ''
  });
  const [errorFileTooBigMsg, setErrorFileTooBigMsg] = useState('');
  const [deviceAffected, setDeviceAffected] = useState('');
  const [isNotesUpdated, setIsNotesUpdated] = useState(false);

  const [additionalInfo, setAdditionalInfo] = useState([
    {
      id: 0,
      checked: 'ITSD_DEFAULTSERVICE',
      hasDesc: true
    },
    {
      id: 1,
      checked: 'Request',
      hasDesc: true
    },
    {
      id: 2,
      checked: 'can work',
      hasDesc: false
    }
  ]);
  const [isTicketSummWithEmojis, setIsTicketSummWithEmojis] = useState(false);
  const [isTicketSummEmpty, setIsTicketSummEmpty] = useState(false);
  const [isTicketDetEmpty, setIsTicketDetEmpty] = useState(false);
  const [isServiceEmptySelection, setIsServiceEmptySelection] = useState(false);
  const [isServicePreSelected, setIsServicePreSelected] = useState(false);
  const [servicePreSelected, setServicePreSelected] = useState(false);
  const [isINCTicketCreated, setIsINCTicketCreated] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [showLimitedError, setShowLimitedError] = useState(false);
  const navigate = useNavigate();

  // Strings
  let errorInTicketAttachmentCreation= t('ui_spear.createTicket.errorInTicketAttachmentCreationV2');

  const radioBtnsTitleSect = [
    t('ui_spear.createTicket.Which_service_is_affected'),
    t('ui_spear.createTicket.What_is_the_type_of'),
    t('ui_spear.createTicket.How_urgent_is_it')
  ];

  const descTitleRadioBtns = [
    t('ui_spear.createTicket.This_function_is_dedicated'),
    t('ui_spear.createTicket.Select_a_request')
  ];

  const radioBtnsContent = [
    [
      {
        text: t('ui_spear.createTicket.I_dont_know'),
        value: 'ITSD_DEFAULTSERVICE'
      },
      {
        text: t('ui_spear.createTicket.Let_me_select_it'),
        value: 'SELECT_SERVICE'
      },
    ],
    [
      {
        text: t('ui_spear.createTicket.request'),
        value: 'Request'
      },
      {
        text: t('ui_spear.createTicket.failure'),
        value: 'Failure'
      }
    ],
    [
      {
        text:
          t('ui_spear.createTicket.I_can_continue_to_work') +
          '  ' +
          t('ui_spear.createTicket.urgency_low'),
        value: 'can work'
      },
      {
        text:
          t('ui_spear.createTicket.I_cannot_continue_to') +
          '  ' +
          t('ui_spear.createTicket.urgency_medium'),
        value: 'cannot work'
      }
    ]
  ];

  useEffect(() => {
    validateSapKeyUser();
  }, [props.userInfo]);

  const validateSapKeyUser = async () => {
    try {
      const sapKeyUser = await getSapKeyUser(props?.userInfo?.userNTID);
      setIsSapUserKey(sapKeyUser?.data?.json);
      setFailureAsDefault(sapKeyUser?.data?.json);
    } catch (error) {
      console.log('Error while verifiying SAP KEY USER', error);
      setIsSapUserKey(false);
    }
  };

  const setFailureAsDefault = async (SapKeyUser) => {
    if (SapKeyUser) {
      setTicketSuppData((prevState) => {
        return {
          ...prevState,
          Incident_Type: 'User Service Restoration',
          Categorization_Tier_1: 'Failure'
        };
      });
      setAdditionalInfo((prevState) => {
        return prevState.map((item) => {
          if (item.id === 1) {
            return {
              ...item,
              checked: 'Failure'
            };
          }
          return item;
        });
      });
    } else {
      return;
    }
  };

  const setServiceFromUrl = async () => {
    // Get the current URL using window.location.href
    const url = window.location.href;
  
    // Early return if URL is invalid or missing
    if (!url) {
      console.error('Invalid URL');
      return;
    }
  
    // Create a URL object to extract query parameters
    const urlObj = new URL(url);
    const service = urlObj.searchParams.get('service');
  
    // Handle case where 'service' parameter is missing
    if (!service) {
      console.log('Service parameter not found in the URL');
      return;
    }
  
    // Mark the service as pre-selected
    setIsServicePreSelected(true);
    setIsServiceEmptySelection(false);
  
    // Update additional info based on the 'service' parameter
    setAdditionalInfo((prevState) =>
      prevState.map((item) =>
        item.id === 0 ? { ...item, checked: 'SELECT_SERVICE' } : item
      )
    );
  
    try {
      // API POST request to fetch the service data
      const serviceURL = '/api/v1/smt/services/reconciliation';
      const response = await axiosInstance.post(serviceURL, {
        serviceReconciliation: service,
      });
  
      // Check if response status is 200
      if (response.status !== 200 || !response.data) {
        throw new Error('Failed to fetch ServiceCI data');
      }
  
      // Extract ServiceCI from response, or use a default if not available
      const ServiceCI = response.data[0]?.CI_NAME || 'Service Not Found';
      
      setServicePreSelected(ServiceCI)
  
      // Update ticketSuppData with the fetched or default ServiceCI
      setTicketSuppData((prevState) => ({
        ...prevState,
        ServiceCI,
        Service: 'SELECT_SERVICE',
        ServiceCI_ReconID: service, // Set the ServiceCI_ReconID to the service value
      }));
    } catch (error) {
      // Handle any errors during API call or data processing
      console.error('Error fetching service data:', error);
  
      // Fallback to default ServiceCI value in case of an error
      setTicketSuppData((prevState) => ({
        ...prevState,
        ServiceCI: 'DEFAULTSERVICE', // Default error value
        Service: 'SELECT_SERVICE',
        ServiceCI_ReconID: service, // Set the ServiceCI_ReconID to the service value
      }));
    }
  };
  


  useEffect(() => {
    setUserData();
    setUserInfoSuppTicket(() => ({ ...props.userInfo }));
    getUserDevices();
    setServiceFromUrl();
  }, [props.userInfo]);

  useEffect(() => {
    // Customer is a third person, selected via change customer option
    if (Array.isArray(userInfoSuppTicket)) {
      let tempUser = userInfoSuppTicket[0]?.CORPORATE_ID;
      getUserDevices(tempUser);
      getUserPhones(tempUser);
    }
  }, [userInfoSuppTicket]);

  useEffect(() => {
    // Customer is a thrid person, selected via change customer option
    if (Array.isArray(userInfoSuppTicket)) {
      setTicketSuppData((prevState) => {
        return {
          ...prevState,
          Customer_ID: userInfoSuppTicket[0]?.CORPORATE_ID,
          Submitter_ID: props.userInfo?.userNTID,
          Contact: props.userInfo?.userNTID
        };
      });
    } else {
      setTicketSuppData((prevState) => {
        return {
          ...prevState,
          Customer_ID: userInfoSuppTicket.userNTID,
          Submitter_ID: userInfoSuppTicket.userNTID
        };
      });
    }
  }, [userInfoSuppTicket]);

  useEffect(() => {
    if (isINCTicketCreated) {
      handleWorklogCreation();
    }
  }, [isINCTicketCreated]);

  useEffect(() => {
    if (worklogDet.incident_number !== '') {
      handleSubmitUpdloadedFiles(incidentId);
    }
  }, [worklogDet, incidentId]);

  useEffect(() => {
    if (isNotesUpdated) {
      submitSMTTicket();
    }
  }, [isNotesUpdated]);

  const handleCloseDetailedTicketDialog = () => {
    setOpenPersonPicker(false);
  };

  const showErrorPopUp = () => {
    setErrorPopUp(!errorPopUp);
  };

  const setUserData = () => {
    let userPhones = [];
    let userTempFullName = props?.userInfo?.userGivenName.concat(
      ' ' + props?.userInfo?.userFamilyName
    );
    hasUserMultiplePhones(userPhones);
    setUserphones(userPhones);
    setUserEmail(props.userInfo?.userEmail ?? 'Loading...');
    setSelectedPhone(userPhones[0]);
    setUserFullName(userTempFullName ?? 'Loading...');
  };

  const hasUserMultiplePhones = (userPhones) => {
    if (props.userInfo) {
      // User has mobile phone and line phone
      if (
        props.userInfo?.userMobilePhone !== '' &&
        props.userInfo?.userPhone !== ''
      ) {
        userPhones.push(
          props.userInfo?.userMobilePhone,
          props.userInfo?.userPhone
        );
        // User has mobile phone but not line phone
      } else if (
        props.userInfo?.userMobilePhone !== '' &&
        props.userInfo?.userPhone === ''
      ) {
        userPhones.push(props.userInfo?.userMobilePhone);
        // User hasnt mobile phone but line phone
      } else if (
        props.userInfo?.userMobilePhone === '' &&
        props.userInfo?.userPhone !== ''
      ) {
        userPhones.push(props.userInfo?.userPhone);
      } else {
        return userPhones;
      }
    }
  };

  const handlePhoneChosen = (e) => {
    setSelectedPhone(e.target.value);
  };

  const handleTicketSummary = (e) => {
    if (ticketSuppData.Summary.length > 100) {
      setTicketSuppData((prevState) => ({
        ...prevState,
        Summary: DOMPurify.sanitize(
          ticketSuppData.Summary.substring(0, 100),
          ConfigDOMPurify.DOMPurifyConfiguration
        )
      }));
    }
    setTicketSuppData((prevState) => ({
      ...prevState,
      Summary: DOMPurify.sanitize(
        e.target.value,
        ConfigDOMPurify.DOMPurifyConfiguration
      )
    }));
  };

  const handleTicketDetails = (e) => {
    if (ticketDetails.length > 10000) {
      setTicketDetails(
        DOMPurify.sanitize(
          ticketDetails.substring(0, 10000),
          ConfigDOMPurify.DOMPurifyConfiguration
        )
      );
    }
    setTicketDetails(
      DOMPurify.sanitize(e.target.value, ConfigDOMPurify.DOMPurifyConfiguration)
    );
  };

  const handleIsTicketSummEmpty = () => {
    if (ticketSuppData.Summary.trim() === '') {
      setIsTicketSummEmpty(true);
      return true;
    } else {
      setIsTicketSummEmpty(false);
      return false;
    }
  };

  const handleIsTicketDetEmpty = () => {
    if (ticketDetails.trim() === '') {
      setIsTicketDetEmpty(true);
      return true;
    } else {
      setIsTicketDetEmpty(false);
      return false;
    }
  };

  const handleIsServiceSelectionEmpty = () => {
    if (ticketSuppData.ServiceCI === 'SELECT_SERVICE') {
      setIsServiceEmptySelection(true);
      return true;
    } else {
      setIsServiceEmptySelection(false);
      return false;
    }
  };

  const toBase64 = (file) =>
    new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result);
      reader.onerror = (error) => rej(error);
    });

  const getEncodedData = (fullBase64) => {
    const splittedData = fullBase64.split(',');
    if (splittedData[1]) {
      return splittedData[1];
    }
    return null;
  };

  const handleUploadFiles = async (files) => {
    const updatedFiles = [...fileList];
    const url = window.URL.createObjectURL(files);
    const fullBase64 = await toBase64(files);
    const base64Data = getEncodedData(fullBase64);
    const newFile = {
      downloadUrl: url,
      file: files,
      fullBase64: fullBase64,
      base64Data: base64Data,
      id: createUniqueID()
    };
    updatedFiles.push(newFile);
    setFileList(updatedFiles);
  };

  const handleWorklogCreation = () => {
    setWorklogDet((prevState) => ({
      ...prevState,
      incident_number: incidentId,
      work_log_submitter: props.userInfo.userNTID
    }));
    if (fileList.length > 0) {
      fileList.forEach((file, i) => {
        let attachPropName = `attach${i + 1}_name`;
        let attachPropBase64 = `attach${i + 1}_base64`;
        let attachPropsize = `attach${i + 1}_size`;
        setWorklogDet((prevState) => ({
          ...prevState,
          [attachPropName]: file.file.name,
          [attachPropBase64]: file.base64Data,
          [attachPropsize]: file.file.size
        }));
      });
    }
  };

  const handleRemoveFiles = (id) => {
    setShowLimitedError(false);
    setFileList((prevState) => prevState.filter((file) => file.id !== id));
  };

  const handleFileChange = (e) => {
    if (fileList.length === 3) {
      setShowLimitedError(true);
      return;
    }
    setShowLimitedError(false);
    if (e.target.files) {
      const chosenFiles = e.target.files[0];
      if (chosenFiles.size >= 5000000) {
        setErrorFileTooBigMsg(
          `${t('ui_spear.createTicket.attachment_cant_be')} ${chosenFiles.name
          } ${t('ui_spear.createTicket.attachment_is_greater')}`
        );
      } else {
        setErrorFileTooBigMsg('');
        handleUploadFiles(chosenFiles);
      }
    }
  };

  const validateEmojisInputs = (incidentDetails) => {
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF]/;
    if (emojiRegex.test(ticketSuppData.Summary) || emojiRegex.test(incidentDetails)) {
      setIsTicketSummWithEmojis(true);
      return false;
    } else {
      setIsTicketSummWithEmojis(false);
      return true;
    }
  };

  const handleSubmitTicket = () => {
    console.log(ticketSuppData.ServiceCI_ReconID)
    if (!handleIsTicketSummEmpty() && !handleIsTicketDetEmpty() && !handleIsServiceSelectionEmpty() && validateEmojisInputs(ticketDetails)) {
      setTicketSuppData((prevState) => ({
        ...prevState,
        ServiceCI_ReconID:
          ticketSuppData.ServiceCI_ReconID === ''
            ? process.env.REACT_APP_ITSD_DEFAULTSERVICE_RECONID
            : ticketSuppData.ServiceCI_ReconID,
        Notes:
          // Case user is selected via change customer
          Array.isArray(userInfoSuppTicket)
            ? `
              ${DOMPurify.sanitize(
              ticketDetails,
              ConfigDOMPurify.DOMPurifyConfiguration
            )}\n\n
              Preferred Email : ${userInfoSuppTicket[0]?.INTERNET_E_MAIL}\n
              Preferred Phone : ${selectedPhone ?? ' '}\n 
              Device Selected : ${deviceAffected ?? ' '}
            `
            : `
              ${DOMPurify.sanitize(
              ticketDetails,
              ConfigDOMPurify.DOMPurifyConfiguration
            )}\n\n
              Preferred Email : ${userInfoSuppTicket.userEmail}\n
              Preferred Phone : ${selectedPhone ?? ' '}\n 
              Device Selected : ${deviceAffected ?? ' '}
            `
      }));
      setIsNotesUpdated(!isNotesUpdated);
      setIsLoaderOpen(true);
    } else {
      return null;
    }
  };

  const submitSMTTicket = () => {
    try {
      const URL = `/api/v1/smt/ticket`;
      axiosInstance
        .post(URL, { ...ticketSuppData })
        .then((res) => {
          setIncidentId(res.data.Incident_Number[0]);
          setIsINCTicketCreated(true);
          setTicketSuppData({
            Customer_ID: '',
            Submitter_ID: '',
            Contact: '',
            Assigned_Group: 'ITSD - IT Service Desk',
            Summary: '',
            Notes: '',
            ServiceCI: 'ITSD_DEFAULTSERVICE',
            Impact: 4000,
            Incident_Type: 'User Service Request',
            Urgency: 4000,
            Priority: 3,
            Reported_Date: new Date().getTime(),
            Submit_Date: new Date().getTime(),
            Priority_Weight: 0,
            ServiceCI_ReconID: '',
            Reported_Source: 4200,
            Status: 'New',
            Categorization_Tier_1: 'Request'
          });
          setTicketDetails('');
        })
        .catch((err) => {
          console.log(err);
          setIsLoaderOpen(false);
          showErrorPopUp();
          setErrorMessage(err.response.data.message);
          setIsNotesUpdated(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitUpdloadedFiles = (incident_id) => {
    if (fileList.length >= 1) {
      const submitURL = `/api/v1/worklog`;
      axiosInstance
        .post(submitURL, worklogDet)
        .then((res) => {
          setWorklogDet({
            incident_number: '',
            work_log_type: 15000,
            notes: 'user attachments',
            work_log_submitter: '',
            attach1_name: null,
            attach1_base64: null,
            attach1_size: null,
            attach2_name: null,
            attach2_base64: null,
            attach2_size: null,
            attach3_name: null,
            attach3_base64: null,
            attach3_size: null,
            linked_document: ''
          });
          setIsLoaderOpen(false);
          navigate('/tickets', {
            state: { inc_id: incidentId },
            replace: true
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoaderOpen(false);
          alert(errorInTicketAttachmentCreation);
          navigate('/tickets', {
            state: { inc_id: incidentId },
            replace: true
          });
        });
    } else {
      setIsLoaderOpen(false);
      navigate('/tickets', {
        state: { inc_id: incidentId },
        replace: true
      });
    }
  };

  const chooseDevAffected = (e) => setDeviceAffected(e.target.value);

  const getUserDevices = async (tempUser) => {
    let payload;
    if (tempUser) {
      payload = tempUser;
    } else {
      payload = props.userInfo?.userNTID;
    }
    const deviceURL = `/api/v1/smt/devices`;
    axiosInstance
      .post(deviceURL, { ntid: payload })
      .then((res) => {
        setUserDevices(res?.data);
      })
      .catch((err) => {
        console.log(`There was an error retrivieng User Devices, ${err}`);
      });
  };

  const handleCustomerChangePhones = (userPhones, mobilePhone, phone) => {
    // User has mobile phone and line phone, for users coming from change customer only
    if (mobilePhone !== '' && phone !== '') {
      userPhones.push(mobilePhone, phone);
      // User has mobile phone but not line phone
    } else if (mobilePhone !== '' && phone === '') {
      userPhones.push(mobilePhone);
      // User hasnt mobile phone but line phone
    } else if (mobilePhone === '' && phone !== '') {
      userPhones.push(phone);
    } else {
      return userPhones;
    }
  };

  const getUserPhones = async (tempUser) => {
    // Used for change customer
    let phones = [];
    let payload;
    if (tempUser) {
      payload = tempUser;
    }
    const phonesURL = `/api/v1/snow/user/phones`;
    axiosInstance
      .post(phonesURL, { ntid: payload })
      .then((res) => {
        // Mobile phone and phone are not the same
        let mobilePhone = res?.data?.data[0]?.mobile_phone;
        let phone = res?.data?.data[0]?.phone;
        handleCustomerChangePhones(phones, mobilePhone, phone);
        setUserphones(phones);
        setSelectedPhone(phones[0]);
      })
      .catch((err) => {
        console.log(`There was an error retrivieng User Phones, ${err}`);
      });
  };

  const renderAdditionalInfoComp = () => {
    return additionalInfo.map((info, i) => {
      return (
        <div
          key={info.title}
          className="grid grid-cols-[1fr_.7fr] sm:flex sm:flex-col md:flex-col lg:grid"
        >
          <AdditionalInfoBtns
            title={radioBtnsTitleSect[i]}
            hasDesc={info.hasDesc}
            desc={info.hasDesc && descTitleRadioBtns[i]}
            ticketSuppData={ticketSuppData}
            options={radioBtnsContent[i]}
            checked={info.checked}
            setTicketSuppData={setTicketSuppData}
            setAdditionalInfo={setAdditionalInfo}
            isServiceEmptySelection={isServiceEmptySelection}
            setIsServicePreSelected={setIsServicePreSelected}
            isServicePreSelected={isServicePreSelected}
            servicePreSelected={servicePreSelected}
          />
          {i === 2 ? <BusinessCriticalTicketInfor /> : <></>}
        </div>
      );
    });
  };

  const renderSubmitCancelBtns = () => {
    const stdBtnStyles = 'w-[7rem] h-12 px-1.5 py-px ml-2.5';
    const btnClassesStyle = [
      'bg-change_cust_btn text-white',
      'border border-change_cust_btn text-change_cust_btn'
    ];
    return [
      t('ui_spear.createTicket.submit'),
      t('ui_spear.createTicket.cancel')
    ].map((btn, i) => {
      return i === 0 ? (
        <button
          key={btn}
          onClick={handleSubmitTicket}
          className={`${stdBtnStyles} ${btnClassesStyle[i]}`}
        >
          {btn}
        </button>
      ) : (
        <Link to="/support">
          <button key={btn} className={`${stdBtnStyles} ${btnClassesStyle[i]}`}>
            {btn}
          </button>
        </Link>
      );
    });
  };

  const renderLoader = () => {
    return isLoaderOpen ? <LoaderTicketCreation /> : <></>;
  };

  return (
    <TooltipContextProvider>
      {renderLoader()}
      <section className="m-6">
        <div className="container mx-auto px-14 w-4/6">
          {errorPopUp ? (
            <ErrorPopUp
              errorPopUp={errorPopUp}
              errorMessage={errorMessage}
              togglePopUp={showErrorPopUp}
              submitError={submitError}
              setOpenChatFromTicket={setOpenChatFromTicket}
              setOpenCallFromTicket={setOpenCallFromTicket}
              openCallFromTicket={openCallFromTicket}
              openChatFromTicket={openChatFromTicket}
            ></ErrorPopUp>
          ) : (
            ''
          )}
          {openPersonPicker === true ? (
            <PersonPicker
              userInfoSuppTicket={userInfoSuppTicket}
              setUserInfoSuppTicket={setUserInfoSuppTicket}
              openPersonPicker={openPersonPicker}
              handleCloseDetailedTicketDialog={handleCloseDetailedTicketDialog}
            ></PersonPicker>
          ) : (
            ''
          )}
          <Link to="/support">
            {'< '}
            {t('ui_spear.createTicket.Back_to_Support')}
          </Link>
          <h1 className="text-4xl">
            {t('ui_spear.createTicket.Open_a_ticket')}
          </h1>
          <BlueBannerImmediateAction />
          <WhoIsAffected
            setOpenPersonPicker={setOpenPersonPicker}
            Name={
              Array.isArray(userInfoSuppTicket)
                ? `${userInfoSuppTicket[0]?.FIRST_NAME} ${userInfoSuppTicket[0]?.LAST_NAME}`
                : `${userFullName}`
            }
            Email={
              Array.isArray(userInfoSuppTicket)
                ? `${userInfoSuppTicket[0]?.INTERNET_E_MAIL}`
                : `${userEmail}`
            }
            Phone={userPhones}
            selectedPhone={selectedPhone}
            handlePhoneChosen={handlePhoneChosen}
          />
          <div className="my-6 grid grid-cols-[1fr_.7fr] sm:flex sm:flex-col md:flex-col lg:grid">
            <div className="w-full">
              <WhatHappened
                ticketSummaryVal={ticketSuppData.Summary}
                ticketDetailsVal={ticketDetails}
                isTicketSummEmpty={isTicketSummEmpty}
                isTicketDetEmpty={isTicketDetEmpty}
                handleTicketSummary={handleTicketSummary}
                handleTicketDetails={handleTicketDetails}
              />
              <AddAttachments
                fileList={fileList}
                errorFileTooBigMsg={errorFileTooBigMsg}
                handleFileChange={handleFileChange}
                handleRemoveFiles={handleRemoveFiles}
                showLimitedError={showLimitedError}
              />
            </div>
            <WritePerfectTicket />
          </div>
          <div className="my-6">
            <DevAffected
              userDevices={userDevices}
              chooseDevAffected={chooseDevAffected}
            />
            {renderAdditionalInfoComp()}
          </div>
          <div>{isTicketSummWithEmojis ? <EmojisNotAllowedMessage /> : null}</div>
          <div>{renderSubmitCancelBtns()}</div>
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <div className="mt-20 mb-12">
              <h3 className="text-[1.75rem] mb-12 font-Bosch_sans_light">
                {t('ui_spear.support.Do_you_need_any_support')}
              </h3>
              <SupportBar
                setOpenChatFromTicket={setOpenChatFromTicket}
                setOpenCallFromTicket={setOpenCallFromTicket}
                openCallFromTicket={openCallFromTicket}
                openChatFromTicket={openChatFromTicket}
                userInfo={props.userInfo}
                fromSupport={true}
                ticketId=""
                fromDial={false}
              />
            </div>
          </Grid>
        </Grid>
      </section>

      {
        <ButtonsSpeedDial showScrollButton={false}>
          <SupportBar
            setOpenChatFromTicket={setOpenChatFromTicket}
            setOpenCallFromTicket={setOpenCallFromTicket}
            openCallFromTicket={openCallFromTicket}
            openChatFromTicket={openChatFromTicket}
            userInfo={props.userInfo}
            fromSupport={true}
            ticketId=""
            fromDial={true}
          ></SupportBar>
        </ButtonsSpeedDial>
      }
    </TooltipContextProvider>
  );
};

export default Ticket;
