import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../../utils/axiosInstance';

const SmtTicketSolutionModal = (props) => {
  const { t } = useTranslation();
  const { incidentId, icon, localizationLabel } = props;
  const [open, setOpen] = useState(false);
  const [resolution, setResolution] = useState('');

  useEffect(() => {
    if (open && incidentId !== '') {
      getTicketDetail();
    }
  }, [open, incidentId]);

  const getTicketDetail = () => {
    let url = `/api/v1/smt/tickets/details/id`;
    let payload = {
      incident_number: incidentId,
      start_record: 0,
      max_limit: 5
    };
    axiosInstance
      .post(url, payload)
      .then((response) => {
        setResolution(
          response.data['soapenv:Envelope']['soapenv:Body'][0]
            .HelpDesk_Query_ServiceResponse[0].Resolution[0]
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        className="group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 p-0 h-14 w-auto text-blue_font hover:text-[#005580] text-[12px] normal-case"
      >
        <span className={`${icon} text-[16px] block pr-[5px]`}></span>
        <span className="capitalize block pr-[5px]">{localizationLabel}</span>
        <div className="transform -rotate-90 group-hover:translate-x-2">
          <span className={`boschicon-bosch-ic-down text text-[18px]`}></span>
        </div>
      </Button>

      <Dialog open={open} keepMounted onClose={handleClose}>
        <DialogTitle className="pt-8 mt-4">
          <div className="font-Bosch_sans text-center relative capitalize text-[26px]">
            <span className={`${icon}`}></span>
            <span className="capitalize"> {localizationLabel} </span>
            <span
              className="absolute top-0 right-0 h-10 w-10 mr-6 boschicon-bosch-ic-close text-[24px] cursor-pointer font-bold"
              onClick={handleClose}
              style={{ fontFamily: 'BoschSans-Bold', fontWeight: 900 }}
            >
              {' '}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="pb-8 m-4 mt-0">
              <div>{t('ui_spear.myTickets.Modal.Your_ticket_was_closed')} </div>
              <div className="my-4 flex flex-row bg-[#E7E7E7]">
                <div className="basis-1/6 p-2 scroll-ps-6 snap-x font-semibold">
                  {t('ui_spear.ticketDetails.Resolution')}:
                </div>
                <div className="basis-5/6 p-2 scroll-ps-6 snap-x w-screen">
                  {resolution}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SmtTicketSolutionModal;
