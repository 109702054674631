import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingButton from './LoadingButton';
import axiosInstance from '../../../utils/axiosInstance';

const VpnBotton = ({ vpnStatus, setVpnStatus, userNTID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _requestVpn = () => {
    let url = `/api/v1/snow/request/device/phone/setting`;
    let status = vpnStatus;
    let statusBolean = vpnStatus === 'Enable' ? '1' : '0';
    let dataReq = {
      requested_for: userNTID,
      variables: {
        service: 'manage VPN',
        enable: statusBolean,
        cat_item: 'Mobile Phone VPN Access'
      }
    };

    setVpnStatus('Inactive');
    axiosInstance
      .post(url, dataReq)
      .then((resp) => {
        localStorage.setItem('vpnEnable', `Pending -  ${status}`);
        navigate('/tickets');
      })
      .catch((e) => {
        console.log('error:', e);
      });
  };

  const _renderVpnButton = () => {
    let vpnButton;

    switch (vpnStatus) {
      case 'Enable':
        vpnButton = (
          <button
            onClick={(e) => _requestVpn()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.enable')}
          </button>
        );
        break;
      case 'Disable':
        vpnButton = (
          <button
            onClick={(e) => _requestVpn()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.disable')}
          </button>
        );
        break;
      case 'Pending':
        vpnButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.pending')}
          </button>
        );
        break;
      case 'Error':
        vpnButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.error')}
          </button>
        );
        break;
      default:
        vpnButton = (
          <button
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light m-0  px-6 py-2"
            style={{ height: 38 }}
          >
            {
              <LoadingButton />
            }
          </button>
        );
        break;
    }
    return vpnButton;
  };

  return (
    <>
      <>{_renderVpnButton()}</>
    </>
  );
};

export default VpnBotton;
