import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchPerson from './SearchPerson';

const FunnelBtn = ({
  statusFilter,
  setStatusFilter,
  typeFilter,
  setTypeFilter,
  priorityFilter,
  setPriorityFilter,
  personFilter,
  setPersonFilter,

  isFiltersActive,
  setIsFiltersActive,

  showFilterContain,
  setShowFilterContain,

  openItemsBackup,
  closedItemsBackup,

  setOpenTickets,
  setOpenAfterFilters,

  setClosedTickets,
  setClosedAfterFilters,

  setCurrentPageOpenTable,
  setCurrentPageClosedTable,
  setSearchText,

  personList,
  toggleTicketsTabs
}) => {
  //Translations
  const { t } = useTranslation();
  const filterTicketsLabel = t('ui_spear.myTickets.FilterTickets');
  const statusLabel = t('ui_spear.myTickets.Status');
  const createdLabel = t('ui_spear.ticketDetails.Details_Created');
  const inApprovalLabel = t('ui_spear.myTickets.In_approval');
  const inProgressLabel = t('ui_spear.myTickets.In_progress');
  const pendingLabel = t('ui_spear.myTickets.Pending');
  const cancelledLabel = t('ui_spear.ticketDetails.Banner.Cancelled');
  const rejectedLabel = t('ui_spear.ticketDetails.Banner.Rejected');
  const closedLabel = t('ui_spear.ticketDetails.Details_Closed');

  const typeLabel = t('ui_spear.myTickets.Type');
  const failureLabel = t('ui_spear.createTicket.failure');
  const requestLabel = t('ui_spear.createTicket.request');

  const priorityLabel = t('ui_spear.myTickets.Priority');
  const criticalLabel = t('ui_spear.myTickets.Priority_Critical');
  const highLabel = t('ui_spear.myTickets.Priority_High');
  const mediumLabel = t('ui_spear.myTickets.Priority_Medium');
  const lowLabel = t('ui_spear.myTickets.Priority_Low');

  const resetAllLabel = t('ui_spear.myTickets.ResetAll');
  const ApplyLabel = t('ui_spear.myTickets.Apply');

  const filterByProperty = (data, property, values) => {
    if (values.length) {
      return data.filter((item) =>
        values.includes(item[property].toLowerCase())
      );
    } else {
      return data;
    }
  };

  const filterByPerson = (data, personName) => {
    if (personName !== '') {
      return data.filter((item) =>
        item['person'].toLowerCase().includes(personName.toLowerCase().trim())
      );
    } else {
      return data;
    }
  };

  const applyFilters = () => {

    //Filter to open table
    let personFilterOpenResult = filterByPerson(openItemsBackup, personFilter);
    let statusFilterOpenResult = filterByProperty(
      personFilterOpenResult,
      'status',
      statusFilter
    );
    let typeFilterOpenResult = filterByProperty(
      statusFilterOpenResult,
      'type',
      typeFilter
    );
    let allFilterOpenResult = filterByProperty(
      typeFilterOpenResult,
      'priority',
      priorityFilter
    );
    setOpenAfterFilters(allFilterOpenResult);
    setOpenTickets(allFilterOpenResult);

    //Filter to closed table
    let personFilterClosedResult = filterByPerson(
      closedItemsBackup,
      personFilter
    );
    let statusFilterClosedResult = filterByProperty(
      personFilterClosedResult,
      'status',
      statusFilter
    );
    let typeFilterClosedResult = filterByProperty(
      statusFilterClosedResult,
      'type',
      typeFilter
    );
    let allFilterClosedResult = filterByProperty(
      typeFilterClosedResult,
      'priority',
      priorityFilter
    );
    setClosedAfterFilters(allFilterClosedResult);
    setClosedTickets(allFilterClosedResult);

    setCurrentPageOpenTable(1);
    setCurrentPageClosedTable(1);
    setSearchText('');
    setShowFilterContain(false);
    hasFiltersApplying();
  };

  const resetAll = () => {
    setStatusFilter([]);
    setTypeFilter([]);
    setPriorityFilter([]);
    setPersonFilter('');

    setOpenAfterFilters(openItemsBackup);
    setOpenTickets(openItemsBackup);

    //reset closed initial values
    setClosedAfterFilters(closedItemsBackup);
    setClosedTickets(closedItemsBackup);

    setCurrentPageOpenTable(1);
    setCurrentPageClosedTable(1);
    setSearchText('');
    setShowFilterContain(false);
    setIsFiltersActive(false);
  };

  const hasFiltersApplying = () => {
    if (
      statusFilter.length > 0 ||
      typeFilter.length > 0 ||
      priorityFilter.length > 0
    ) {
      setIsFiltersActive(true);
    } else {
      setIsFiltersActive(false);
    }
  };

  const handleStatusCheckboxes = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setStatusFilter([...statusFilter, name]);
    } else {
      setStatusFilter(statusFilter.filter((item) => item !== name));
    }
  };
  const handleTypeCheckboxes = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setTypeFilter([...typeFilter, name]);
    } else {
      setTypeFilter(typeFilter.filter((item) => item !== name));
    }
  };
  const handlePriorityCheckboxes = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setPriorityFilter([...priorityFilter, name]);
    } else {
      setPriorityFilter(priorityFilter.filter((item) => item !== name));
    }
  };
  return (
    <div className="w-10 h-10 flex justify-center items-center relative">
      <span
        className={`boschicon-bosch-ic-filter text text-[26px] mt-2 cursor-pointer ${
          isFiltersActive && 'bg-gray-200 rounded-full'
        }`}
        onClick={() => setShowFilterContain((prev) => !prev)}
      ></span>
      <div
        className={` ${
          showFilterContain ? 'flex-row' : 'hidden'
        } w-[459px] absolute top-10 right-2 up-arrow z-10`}
      >
        <h3 className=" text-lg text-center my-5 font-light">
          {filterTicketsLabel}
        </h3>
        {toggleTicketsTabs.ticketsForOthers && (
          <SearchPerson
            options={personList}
            personFilter={personFilter}
            setPersonFilter={setPersonFilter}
          />
        )}
        <div className=" flex mx-4">
          <ul className=" w-1/3">
            <li className="mx-2 font-medium text-lg">{statusLabel}</li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="created"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('created')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{createdLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="in approval"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('in approval')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{inApprovalLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="in progress"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('in progress')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{inProgressLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="pending"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('pending')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{pendingLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="cancelled"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('cancelled')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{cancelledLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="rejected"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('rejected')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{rejectedLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="closed"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={statusFilter.includes('closed')}
                onChange={handleStatusCheckboxes}
              />
              <span className="ml-2 text-lg">{closedLabel}</span>
            </li>
          </ul>

          <ul className=" w-1/3">
            <li className="mx-2 font-medium text-lg">{typeLabel}</li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="failure"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={typeFilter.includes('failure')}
                onChange={handleTypeCheckboxes}
              />
              <span className="ml-2 text-lg">{failureLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="request"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={typeFilter.includes('request')}
                onChange={handleTypeCheckboxes}
              />
              <span className="ml-2 text-lg">{requestLabel}</span>
            </li>
          </ul>

          <ul className=" w-1/3">
            <li className="mx-2 font-medium text-lg">{priorityLabel}</li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="critical"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={priorityFilter.includes('critical')}
                onChange={handlePriorityCheckboxes}
              />
              <span className="ml-2 text-lg">{criticalLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="high"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={priorityFilter.includes('high')}
                onChange={handlePriorityCheckboxes}
              />
              <span className="ml-2 text-lg">{highLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="medium"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={priorityFilter.includes('medium')}
                onChange={handlePriorityCheckboxes}
              />
              <span className="ml-2 text-lg">{mediumLabel}</span>
            </li>
            <li className="mx-2">
              <input
                type="checkbox"
                name="low"
                className="h-6 w-6 mt-2 cursor-pointer"
                checked={priorityFilter.includes('low')}
                onChange={handlePriorityCheckboxes}
              />
              <span className="ml-2 text-lg">{lowLabel}</span>
            </li>
          </ul>
        </div>

        <div className="flex justify-between m-4">
          <button
            className=" px-4 py-1 text-ITSP_blue flex items-center"
            onClick={resetAll}
          >
            <div className="transform scale-x-[-1]">
              <span
                className={`boschicon-bosch-ic-reset text text-[18px]`}
              ></span>
            </div>
            <span className="ml-1 text-lg">{resetAllLabel}</span>
          </button>
          <button
            className="bg-ITSP_blue text-white px-9 py-2 border text-lg"
            onClick={applyFilters}
          >
            {ApplyLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FunnelBtn;
