/**
 * Get the Teams URL based on the user's country code.
 * @param {string} countryCode - The country code of the user.
 * @returns {string} - The Teams call URL.
 */
export const getTeamsUrl = (countryCode) => {
  const defaultTeamsEmailMapping = '{"DE":"tip.de@de.bosch.com"}';
  let teamsEmailMapping;

  try {
    const teamsEmailEnv = process.env.REACT_APP_TEAMS_EMAIL_MAPPING ?? defaultTeamsEmailMapping; // Fallback for environment variable
    teamsEmailMapping = JSON.parse(teamsEmailEnv);
  } catch (error) {
    console.error("Failed to parse variable: REACT_APP_TEAMS_EMAIL_MAPPING");
    teamsEmailMapping = JSON.parse(defaultTeamsEmailMapping); // Fallback to default mapping
  }


  // Look up the full Teams email using the country code (default to US if not found)
  const teamsEmail = teamsEmailMapping[countryCode.toUpperCase()] || "tip.de@de.bosch.com";
  const baseEmailURL = process.env.REACT_APP_TEAMS_BASE_URL ?? 'https://teams.microsoft.com/l/call/0/0?users='

  // Return the full Teams URL
  return `${baseEmailURL}${teamsEmail}`;
};

/**
 * Validate if the user has access to the Teams beta URL based on their country.
 * @param {Object} userInfo - The user information object, containing ntId, location, and other details.
 * @returns {string} - The Teams URL if authorized, or a message saying the user is not authorized.
 */
export const validateTeamsURL = (userInfo) => {
  // Get the list of eligible countries from the environment variable
  let pilotTeamsCall = process.env.REACT_APP_ENTITLE_TEAMS_CALL ?? 'DE';
  let countryIds = pilotTeamsCall ? pilotTeamsCall.split(', ') : [];
  
  // Check if the user's country is part of the entitled countries
  for (let key of countryIds) {
    if (userInfo.userCountry.toUpperCase() === key.toUpperCase()) {
      return true;
    }
  }

  // User is not authorized for the beta link
  return false;
};