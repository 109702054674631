import { HELP_DESK_INCIDENT_TYPE, HELP_DESK_REPORTED_SOURCE, HELP_DESK_PRIORITY } from './smtMappingValues';
import { getEnvironment } from './getEnvironment';

export const getFeedbackUrl =
    (
        serviceCI,
        offeringId,
        source,
        sourceCategory,
        surveyStyle,
        pageId,
        userCountryId,
        userGB,
        solutionGroup,
        configItemId,
        priority,
        reportedSource,
        incidentType
    ) => {
        let feedbackURL = '';

        let priorityValue = HELP_DESK_PRIORITY[priority];
        let reportedSourceValue = HELP_DESK_REPORTED_SOURCE[reportedSource];
        let incidentTypeValue = HELP_DESK_INCIDENT_TYPE[incidentType];

        let feedbackStruct = {
            serviceCI,
            offeringId,
            source,
            sourceCategory,
            stage: getEnvironment() === 'prod' ? 'Prod' : 'Dev',
            surveyStyle,
            pageId,
            userCountryId,
            userGB,
            timestamp: new Intl.DateTimeFormat('de-DE').format(new Date()),
            solutionGroup,
            configItemId,
            priority: priorityValue,
            reportedSource: reportedSourceValue,
            incidentType: incidentTypeValue
        };

        feedbackURL = 'https://boschdigital.qualtrics.com/jfe/form/SV_et9eWrQTWuzZym2?' +
            'OfferingID=' + feedbackStruct.offeringId +
            '&Offering=' + encodeURIComponent(feedbackStruct.serviceCI) +
            (feedbackStruct.source ? '&Source=' + encodeURIComponent(feedbackStruct.source) : '') +
            (feedbackStruct.sourceCategory ? '&SourceCategory=' + encodeURIComponent(feedbackStruct.sourceCategory) : '') +
            (feedbackStruct.stage ? '&Stage=' + encodeURIComponent(feedbackStruct.stage) : '') +
            (feedbackStruct.surveyStyle ? '&SurveyStyle=' + encodeURIComponent(feedbackStruct.surveyStyle) : '') +
            (feedbackStruct.pageId ? '&PageID=' + encodeURIComponent(feedbackStruct.pageId) : '') +
            (feedbackStruct.userCountryId ? '&UserCountryID=' + encodeURIComponent(feedbackStruct.userCountryId) : '') +
            (feedbackStruct.userGB ? '&UserGB=' + encodeURIComponent(feedbackStruct.userGB) : '') +
            (feedbackStruct.timestamp ? '&CustomField1=' + encodeURIComponent(feedbackStruct.timestamp) : '') +
            (feedbackStruct.solutionGroup ? '&CustomField2=' + encodeURIComponent(feedbackStruct.solutionGroup) : '') +
            (feedbackStruct.configItemId ? '&CustomField3=' + encodeURIComponent(feedbackStruct.configItemId) : '') +
            (feedbackStruct.priority ? '&CustomField4=' + encodeURIComponent(feedbackStruct.priority) : '') +
            (feedbackStruct.reportedSource ? '&CustomField5=' + encodeURIComponent(feedbackStruct.reportedSource) : '') +
            (feedbackStruct.incidentType ? '&CustomField6=' + encodeURIComponent(feedbackStruct.incidentType) : '');

        return feedbackURL;

    };

