import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import PseFeedback from './PseFeedback';
import { useTranslation } from 'react-i18next';
import TooltipSearchBar from '../TooltipSearchBar/TooltipSearchBar';
import { useContext } from 'react';
import { TooltipContext } from '../../context/TooltipContext';

const Feedback = (props) => {
  const { t } = useTranslation();
  const [shareFeedBackFlag, setShareFeedBackFlag] = useState(false);
  const [tooltipCount, setTooltipCount] = useContext(TooltipContext);

  const handleClick = () => {
    setShareFeedBackFlag(true);
  };
  return (
    <>
      {props.first ? (
        <TooltipSearchBar
          open={tooltipCount === 3}
          index={3}
          handleNextTipButton={() => {}}
          closeTooltip={() => {}}
        >
          <Grid
            container
            align="left"
            className="bg-neutral-100 h-[70px] bosh_sans"
          >
            {!shareFeedBackFlag ? (
              <div className="flex items-center font-Bosch_sans">
                <div className="text-lg text-neutral-800 ml-3.5 mr-2.5">
                  {t('ui_spear.search.Did_this_article_solve')}
                </div>
                <Button
                  variant="contained"
                  className="rounded-none bg-feedback_btn hover:bg-feedback_btn_hover mx-1 h-[32px] min-w-[66px]"
                  onClick={handleClick}
                >
                  <div className="text-lg text-white normal-case">
                    <div className="boschicon-bosch-ic-thumb-up mx-1 inline-block"></div>
                    {t('ui_spear.search.Button.Yes')}
                  </div>
                </Button>
                <Button
                  variant="contained"
                  className="rounded-none bg-feedback_btn hover:bg-feedback_btn_hover mx-1 h-[32px] min-w-[66px]"
                  onClick={handleClick}
                >
                  <div className="text-lg text-white normal-case">
                    <div className="boschicon-bosch-ic-thumb-down mx-1  inline-block"></div>
                    {t('ui_spear.search.Button.No')}
                  </div>
                </Button>
              </div>
            ) : (
              <PseFeedback {...props}></PseFeedback>
            )}
          </Grid>
        </TooltipSearchBar>
      ) : (
        <Grid
          container
          align="left"
          className="bg-neutral-100 h-[70px] bosh_sans"
        >
          {!shareFeedBackFlag ? (
            <div className="flex items-center font-Bosch_sans">
              <div className="text-lg text-neutral-800 ml-3.5 mr-2.5">
                {' '}
                {t('ui_spear.search.Did_this_article_solve')}{' '}
              </div>
              <Button
                variant="contained"
                className="rounded-none bg-feedback_btn hover:bg-feedback_btn_hover mx-1 h-[32px] min-w-[66px]"
                onClick={handleClick}
              >
                <div className="text-lg text-white normal-case">
                  <div className="boschicon-bosch-ic-thumb-up mx-1 inline-block"></div>
                  {t('ui_spear.search.Button.Yes')}
                </div>
              </Button>
              <Button
                variant="contained"
                className="rounded-none bg-feedback_btn hover:bg-feedback_btn_hover mx-1 h-[32px] min-w-[66px]"
                onClick={handleClick}
              >
                <div className="text-lg text-white normal-case">
                  <div className="boschicon-bosch-ic-thumb-down mx-1  inline-block"></div>
                  {t('ui_spear.search.Button.No')}
                </div>
              </Button>
            </div>
          ) : (
            <PseFeedback {...props}></PseFeedback>
          )}
        </Grid>
      )}
    </>
  );
};

export default Feedback;
