/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { Typography, Grid, Paper, Button } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import ServiceModal from './Services/ServiceModal';
import { useTranslation } from 'react-i18next';

const AdditionalInfoBtns = ({
  title,
  options,
  setTicketSuppData,
  ticketSuppData,
  checked,
  hasDesc,
  desc,
  setAdditionalInfo,
  isServiceEmptySelection,
  isServicePreSelected,
  servicePreSelected
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [serviceButon, setServiceButon] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(servicePreSelected)
    if(isServicePreSelected) {
      setServiceButon(true);
    }
  }, [ticketSuppData])

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'simple-popover' : undefined;

  const handleRadioBtnsChange = (text) => {
    switch (text) {
      // a service is affected
      case 'ITSD_DEFAULTSERVICE':
        setServiceButon(false);
        setTicketSuppData({
          ...ticketSuppData,
          Service: text,
          ServiceCI: text,
          ServiceCI_ReconID: process.env.REACT_APP_ITSD_DEFAULTSERVICE_RECONID
        });
        setAdditionalInfo((prevState) => {
          return prevState.map((item) => {
            if (item.id === 0) {
              return {
                ...item,
                checked: text
              };
            }
            return item;
          });
        });
        break;
      case 'SELECT_SERVICE':
        setServiceButon(true);
        setTicketSuppData({
          ...ticketSuppData,
          Service: text,
          ServiceCI: text
        });
        setAdditionalInfo((prevState) => {
          return prevState.map((item) => {
            if (item.id === 0) {
              return {
                ...item,
                checked: text
              };
            }
            return item;
          });
        });
        break;
      case 'Request':
      case 'Failure':
        let incType =
          text === 'Request'
            ? 'User Service Request'
            : 'User Service Restoration';
        let catTier = text === 'Request' ? 'Request' : 'Failure';
        setTicketSuppData({
          ...ticketSuppData,
          Incident_Type: incType,
          Categorization_Tier_1: catTier
        });
        setAdditionalInfo((prevState) => {
          return prevState.map((item) => {
            if (item.id === 1) {
              return {
                ...item,
                checked: text
              };
            }
            return item;
          });
        });
        break;
      // urgency of the ticket
      case 'can work':
      case 'cannot work':
        let urgency = text === 'can work' ? 4000 : 3000;
        let priority = text === 'can work' ? 3 : 2;
        let priorityWeight = text === 'can work' ? 0 : 10;
        setTicketSuppData({
          ...ticketSuppData,
          Urgency: urgency,
          Priority: priority,
          Priority_Weight: priorityWeight
        });
        setAdditionalInfo((prevState) => {
          return prevState.map((item) => {
            if (item.id === 2) {
              return {
                ...item,
                checked: text
              };
            }
            return item;
          });
        });
        break;
      default:
        break;
    }
  };

  const renderTitleFormAndDesc = (hasDescription, desc) => {
    return hasDescription ? (
      <>
        <div className="flex flex-row">
          <h1 className="mb-9 font-bold">{title}</h1>
          <button className="mb-9 ml-5" onClick={handlePopoverOpen}>
            <span
              style={{
                fontFamily: 'bosch-icon',
                fontWeight: 400,
                color: '#005691'
              }}
            >
              &#xe1ac;
            </span>
          </button>
          {openPopover && (
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <Paper
                onClose={handlePopoverClose}
                sx={{ width: '30rem', zIndex: 30, marginBottom: '-4rem' }}
              >
                <Grid className="inline-flex">
                  <Typography sx={{ p: 2, paddingBottom: '-20px' }}>
                    {desc}
                  </Typography>
                  <span
                    onClick={handlePopoverClose}
                    className="bosch-icon-np mt-4 mr-4 text-[1.5rem] cursor-pointer"
                  >
                    &#xe0f0;
                  </span>
                </Grid>
              </Paper>
            </ClickAwayListener>
          )}
        </div>
      </>
    ) : (
      <h1 className="mb-9 font-bold">{title}</h1>
    );
  };

  const renderRadioBtns = (btns) => {
    // check by state ticketSuppData
    return btns.map((btn, i) => {
      return (
        <>
          <label key={btn.text} className="w-96 radio-btn-container">
            {btn.text}
            {btn.value === 'SELECT_SERVICE' && isServiceEmptySelection && (
              <Typography className='text-red-500 font-bold'>{t('ui_spear.createTicket.Select_a_service')}</Typography>
            )}
            <input
              type="radio"
              name="radio"
              value={btn.value}
              checked={checked === btn.value}
              onChange={() => handleRadioBtnsChange(btn.value)}
              className=''
            />
            <span className="checkmark"></span>
          </label>
          {btn.value === 'SELECT_SERVICE' && serviceButon && !servicePreSelected && (
            <>
              <Grid className="relative top-16 mb-10 left-[-21.5rem] font-Bosch_sans_light">
                <ServiceModal
                  setTicketSuppData={setTicketSuppData}
                  ticketSuppData={ticketSuppData}
                />
              </Grid>
            </>
          )}
          {btn.value === 'SELECT_SERVICE' && servicePreSelected &&  isServicePreSelected && (
            <>
            <Grid className="flex relative max-w-none top-16 mb-10 left-[-18.5rem] font-Bosch_sans_light">
              <Button
                className="border border-change_cust_btn rounded-none text-[0.9rem] p-6 h-10 hover:text-hover_blue_btns hover:border-hover_blue_btns capitalize mt-2"
                variant="outlined"
              >
                Preselected Service: {servicePreSelected}
              </Button>
            </Grid>
            </>
          )}
        </>
      );
    });
  };
  return (
    <div className="mt-11 mb-5">
      {renderTitleFormAndDesc(hasDesc, desc)}
      <form action="" className="flex flex-row">
        {renderRadioBtns(options)}
      </form>
    </div>
  );
};

export default AdditionalInfoBtns;
