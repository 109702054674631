import React, { useRef, useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getFeedbackUrl } from '../../../../utils/feedbackUrl';
import {
  Button,
  Dialog,
  DialogContent,
  styled,
  Typography,
  Popover,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../../utils/axiosInstance';
import Loader from '../../../../components/Confirmations/Loader';
import { getServiceOfferingID } from '../../../../api/ticketsEndpoints';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9'
  }
}));

const RateCloseTicketsModal = (props) => {
  const { t } = useTranslation();
  const { itspUrl, icon, label, source, userInfo, incidentId } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itspFeedbackUrl, setItspFeedbackUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const classIframe = source === 'smt' ? 'w-full h-[92vh]' : 'w-full h-[40vh]';
  const size = source === 'smt' ? 'lg' : 'sm';
  const feedbackAttributesAPI = `/api/v1/smt/feedback/attibutes/`;
  const feedbackDataByIncidentAPI = `/api/v1/smt/ticket/feedback/data`;


  const style =
    label.label == 'Rate'
      ? 'text-blue-500 hover:text-blue-800'
      : 'text-gray-400';

  const handleClickOpen = (e) => {
    e.stopPropagation();

    if (source === 'smt') {
      rateSmtTicket();
    }
    if (source === 'sn' || source === 'cpsc') {
      rateSnowTicket();
    }

    setOpen(true);

  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handlePopOverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopOverClose = () => {
    setAnchorEl(null)
  }

  const rateSmtTicket = () => {
    setLoading(true);
    if (incidentId) {
      axiosInstance
        .post(feedbackDataByIncidentAPI, { incident_number: incidentId })
        .then((responseIncidentData) => {
          if (responseIncidentData.status === 200 && responseIncidentData.data?.status === 200) {
            if (responseIncidentData.data.json[0]?.SERVICE) {
              axiosInstance
                .post(feedbackAttributesAPI, { serviceCI: responseIncidentData.data.json[0]?.SERVICE })
                .then((responseFeedbackAttributes) => {
                  if (responseFeedbackAttributes.status === 200) {
                    if (responseFeedbackAttributes.data[1]?.ATTRIBUTE_VALUE && responseFeedbackAttributes.data[1]?.ATTRIBUTE_VALUE === 'Yes') {
                      const feedbackUrlValue = getFeedbackUrl(
                        responseIncidentData.data.json[0].SERVICE,
                        responseFeedbackAttributes.data[0].ATTRIBUTE_VALUE,
                        'ITSP',
                        'Support',
                        'Flyout',
                        'My tickets page',
                        userInfo.userCountry,
                        userInfo.userBusinessUnit,
                        responseIncidentData.data.json[0].ASSIGNED_GROUP,
                        null, //pending for a new integration!!
                        responseIncidentData.data.json[0].PRIORITY,
                        responseIncidentData.data.json[0].REPORTED_SOURCE,
                        responseIncidentData.data.json[0].INCIDENT_TYPE,
                      )
                      setItspFeedbackUrl(feedbackUrlValue);
                      setLoading(false);
                    } else {
                      // this means feedback was =! to 'Yes' and pop-up will a return a generic message.
                      setItspFeedbackUrl(`https://boschdigital.qualtrics.com/jfe/form/SV_0BNw3iZ9r5VZpZ4?Offering=${responseIncidentData.data.json[0].SERVICE}&Source=ITSP&SurveyStyle=Flyout&PageID=My%20tickets%20page`);
                      setLoading(false);
                    }
                  } else {
                    setLoading(false);
                    const feedbackUrlValue = getFeedbackUrl();
                    setItspFeedbackUrl(feedbackUrlValue);
                    console.log(`The responseFeedbackAttributes.status is: ${responseFeedbackAttributes.status}`);
                  }
                })
                .catch((error) => {
                  const feedbackUrlValue = getFeedbackUrl();
                  setItspFeedbackUrl(feedbackUrlValue);
                  setLoading(false);
                  console.log(
                    'Error while getting feedbackAttributesAPI data: ',
                    error
                  );

                });
            } else {
              const feedbackUrlValue = getFeedbackUrl();
              setItspFeedbackUrl(feedbackUrlValue);
              console.log(`The serviceCI: ${responseIncidentData.data.json[0]?.SERVICE} is empty or incorrect`);
              setLoading(false);
            }
          } else {
            const feedbackUrlValue = getFeedbackUrl();
            setItspFeedbackUrl(feedbackUrlValue);
            setLoading(false);
            console.log(`The responseIncidentData.status is: ${responseIncidentData.status} and
              The responseIncidentData.data.status is: ${responseIncidentData.data?.status} with 
              the following message: ${responseIncidentData.data?.message}`);
          }
        })
        .catch((error) => {
          setLoading(false);
          const feedbackUrlValue = getFeedbackUrl();
          setItspFeedbackUrl(feedbackUrlValue);
          console.log(
            'Error while getting feedbackDataByIncidentAPI data: ',
            error
          );
        });
    } else {
      setLoading(false);
      const feedbackUrlValue = getFeedbackUrl();
      setItspFeedbackUrl(feedbackUrlValue);
      console.log(`The incident_number: ${incidentId} is empty or incorrect`);
    }
  }

  const rateSnowTicket = async () => {
    setLoading(true);
    try {
      const responseOfferingID = await getServiceOfferingID(encodeURIComponent(props.serviceName));
      if (responseOfferingID.isSuccessful === true) {
        if (responseOfferingID.data[0].result.length > 0) {
          const feedbackUrlValue = getFeedbackUrl(
            props.serviceName,
            responseOfferingID.data[0].result[0].u_offering_id.value,
            'ITSP',
            'Support',
            'Flyout',
            'My tickets page',
            userInfo.userCountry,
            userInfo.userBusinessUnit,
          )
          setItspFeedbackUrl(feedbackUrlValue);
          setLoading(false);
        } else {
          setItspFeedbackUrl(`https://boschdigital.qualtrics.com/jfe/form/SV_0BNw3iZ9r5VZpZ4?Offering=${props.serviceName}&Source=ITSP&SurveyStyle=Flyout&PageID=My%20tickets%20page`);
          setLoading(false);
        }
      } else {
        const feedbackUrlValue = getFeedbackUrl();
        setItspFeedbackUrl(feedbackUrlValue);
        setLoading(false);
      }
    } catch (error) {
      const feedbackUrlValue = getFeedbackUrl();
      setItspFeedbackUrl(feedbackUrlValue);
      setLoading(false);
    }

  }

  const openPopOver = Boolean(anchorEl)

  return (
    <>
      {
        label.label != 'Expired' &&
        <Button
          onClick={handleClickOpen}
          className={`${style} group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 h-14 w-auto text-blue_font hover:text-[#005580] text-[12px] normal-case`}
        >
          <span className={`text-[16px] pr-1 ${icon}`}></span>
          <span className="capitalize"> {label.localizedLabel}</span>
        </Button>
      }

      {
        label.label == 'Expired' &&
        <Button
          className={`${style} group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 h-14 w-auto text-blue_font text-[12px] normal-case`}
        >
          <span className={`text-[16px] pr-1 ${icon}`}></span>
          <span
            onMouseEnter={(event) => { handlePopOverOpen(event) }}
            onMouseLeave={() => { handlePopOverClose() }} className="capitalize"> {label.localizedLabel}</span>
        </Button>
      }

      <Popover
        style={{ top: '-10px', left: '25px' }}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handlePopOverClose}
        disableRestoreFocus
        PaperProps={{
          style: {
            maxWidth: '250px',
            overflow: 'initial',
            borderRadius: 0,
            boxShadow: 'none',
            border: '1px solid #ACACAC'
          }
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "5px",
            "&::after": {
              content: '""',
              position: 'absolute',
              width: '12px',
              height: '12px',
              left: '90%',
              top: '57px',
              transform: 'translate(-50%,50%)',
              rotate: '45deg',
              backgroundColor: '#FFFFFF',
              borderBottom: '1px solid #ACACAC',
              borderRight: '1px solid #ACACAC',
              boxShadow: '0 1px 8px transparent',
            }
          }}
        />
        <Typography style={{ width: '100%', textAlign: 'center', padding: '10px' }}>
          {t('ui_spear.myTickets.You_may_leave_a_feedback')}
        </Typography>
      </Popover>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <span
            className="absolute top-0 right-0 h-10 w-10 mt-2 mr-6 boschicon-bosch-ic-close text-[24px] cursor-pointer font-bold"
            onClick={handleClose}
            style={{ fontFamily: 'BoschSans-Bold', fontWeight: 900 }}
          >
            {' '}
          </span>
          <div className="m-4">
            {loading
              ? (
                <div className='py-64'>
                  <Loader customStyle="inset-x-1/2 inset-y-1/2" />
                </div>
              )
              : (
                <iframe
                  style={{ height: '680px' }}
                  id="feedback-form"
                  title={label.label}
                  className={`flex items-center justify-center m-auto p-0 ${classIframe}`}
                  src={itspFeedbackUrl}
                  height={800}
                ></iframe>
              )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RateCloseTicketsModal;
