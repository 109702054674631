import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { Fragment, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import axiosInstance from '../../utils/axiosInstance';

const StledSuggestedButton = styled(Button)({
  backgroundColor: '#005691 !important',
  color: '#ffffff',
  textTransform: 'none',
  boxSizing: 'border-box',
  border: '1px solid #005691',
  marginTop: '10px'
});

let statusLabel = '';
let statusIcon = 'boschicon-bosch-ic-refresh';
let statusMessage = '';
let statusLevel = 1;

const TicketJourney = (props) => {
  const { t } = useTranslation();
  const [delegate, setDelegate] = useState('');
  const performerDept = props.TicketJourneyPage.performerDept && props.TicketJourneyPage.performerDept !== 'null' ? `(${props.TicketJourneyPage.performerDept})` : '';

  useEffect(() => {
    if (props.TicketJourneyPage.performerLogin != null) {
      const url = `/api/v1/snow/user/delegate`;
      axiosInstance
        .get(`${url}?ntid=${props.TicketJourneyPage.performerLogin}`)
        .then((result) => {
          setDelegate(result.data.data[0].delegateFullName);
        });
    }
  }, [props.TicketJourneyPage.performerLogin]);
  const formatedDate = (originalDate, useFormat) => {
    var months = [
      t('ui_spear.myTickets.January'),
      t('ui_spear.myTickets.February'),
      t('ui_spear.myTickets.March'),
      t('ui_spear.myTickets.April'),
      t('ui_spear.myTickets.May'),
      t('ui_spear.myTickets.June'),
      t('ui_spear.myTickets.July'),
      t('ui_spear.myTickets.August'),
      t('ui_spear.myTickets.September'),
      t('ui_spear.myTickets.October'),
      t('ui_spear.myTickets.November'),
      t('ui_spear.myTickets.December')
    ];
    const date = new Date(originalDate);
    if (!isNaN(date.getDate())) {
      const day = date.getDate().toString();
      const month = months[date.getMonth()];
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const strTime = `${hours}:${minutes} ${ampm}`;
      const monthIndex = months.indexOf(month);

      if (!useFormat) {
        return `${date.getFullYear()}/${monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1
          }/${day < 10 ? '0' + day : day} ${strTime}`;
      } else {
        return `${day < 10 ? '0' + day : day} ${month},  ${strTime}`;
      }
    }
  };

  const formatedDateWithOutTime = (originalDate) => {
    var months = [
      t('ui_spear.myTickets.January'),
      t('ui_spear.myTickets.February'),
      t('ui_spear.myTickets.March'),
      t('ui_spear.myTickets.April'),
      t('ui_spear.myTickets.May'),
      t('ui_spear.myTickets.June'),
      t('ui_spear.myTickets.July'),
      t('ui_spear.myTickets.August'),
      t('ui_spear.myTickets.September'),
      t('ui_spear.myTickets.October'),
      t('ui_spear.myTickets.November'),
      t('ui_spear.myTickets.December')
    ];
    const date = new Date(originalDate);
    if (!isNaN(date.getDate())) {
      const day = date.getDate().toString();
      const month = months[date.getMonth()];
      const monthIndex = months.indexOf(month);

      return `${date.getFullYear()}/${monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1
        }/${day < 10 ? '0' + day : day}`;
    }
  };

  switch (String(props.TicketJourneyPage.status).toLowerCase()) {
    case 'created':
    case 'assigned':
      statusLabel = `${t('ui_spear.ticketDetails.Details_Created')}`;
      statusIcon = 'boschicon-bosch-ic-ticket';
      statusMessage = `${t(
        'ui_spear.ticketDetails.Comments.RequestProcessed'
      )}`;
      statusLevel = 1;
      break;
    case 'in_progress': // sn response
    case 'in progress': // sn response
    case 'In Progress':
    case 'ongoing':
      statusLabel = `${t('ui_spear.ticketDetails.Banner.In_Progress')}`;
      statusIcon = 'boschicon-bosch-ic-refresh';
      statusMessage = `${t(
        'ui_spear.ticketDetails.Expert_is_working_on_your_ticket'
      )}`;
      statusLevel = 3;
      break;
    case 'in approval': // sn response
    case 'in_approval':
      statusLabel = `${t('ui_spear.ticketDetails.TicketJourney.In_Approval')}`;
      statusIcon = 'boschicon-bosch-ic-coffee-break';
      statusMessage = `${t(
        'ui_spear.ticketDetails.Please_wait_until_your'
      )} <br/> ${props.TicketJourneyPage.performer} ${performerDept}
         <br/> ${delegate}`;
      statusLevel = 2;
      break;
    case 'resolved':
      statusLabel = `${t('ui_spear.myTickets.Closed')}`;
      statusIcon = 'boschicon-bosch-ic-lock-closed';
      statusMessage = `${t('ui_spear.ticketDetails.Your_request_was_closed')}`;
      statusLevel = 4;
      break;
    case 'closed':
    case 'completed':
      statusLabel = `${t('ui_spear.myTickets.Closed')}`;
      statusIcon = 'boschicon-bosch-ic-lock-closed';
      statusMessage = `${t('ui_spear.ticketDetails.Request_was_realized')}`;
      statusLevel = 4;
      break;
    case 'rejected':
    case 'Rejected':
      statusLabel = `${t('ui_spear.ticketDetails.Banner.Rejected')}`;
      statusIcon = 'boschicon-bosch-ic-abort-frame';
      statusMessage = `${t(
        'ui_spear.ticketDetails.Your_request_was_rejected_by'
      )} 
      ${props.TicketJourneyPage.performer} ${performerDept}`;
      statusLevel = 4;
      break;
    case 'cancelled':
      statusLabel = `${t('ui_spear.ticketDetails.Banner.Cancelled')}`;
      statusIcon = 'boschicon-bosch-ic-abort-frame';
      statusMessage = `${t(
        'ui_spear.ticketDetails.Your_request_was_cancelled'
      )}`;
      statusLevel = 4;
      break;
    case 'Pending':
    case 'pending':
      statusLabel = `${t('ui_spear.myTickets.Pending')}`;
      statusIcon = 'boschicon-bosch-ic-clock';
      statusLevel = 3;
      break;
    default:
      statusLevel = 1;
  }
  const statusMessageOriginal = statusMessage;
  const dividedMessagePerformer = statusMessageOriginal.split('. ');
  const statusAndPerformer = dividedMessagePerformer.map((line, index) => (
    <p key={index}>{line}</p>
  ));

  const inactiveProgressStep = 'before:bg-grey_step after:bg-grey_step';
  const activeProgressStep =
    'before:bg-support_banner_color_1 after:bg-support_banner_color_1';
  let bulletPositionProgress = statusLevel;

  const handleDatesProgress = (progress) => {
    if (statusLevel === 4) {
      switch (progress) {
        case 'In progress':
          return props.source === 'smt'
            ? formatedDate(props.TicketJourneyPage.originalSubmittedDate, false)
            : props.TicketJourneyPage.submittedDate;
        case 'Ticket closed':
          return props.source === 'smt'
            ? formatedDate(props.TicketJourneyPage.originalLastDateTime, false)
            : props.TicketJourneyPage.lastDateTime.split(' ')[0];
        default:
          return '';
      }
    }
    return '';
  };

  const renderProgress = () => {
    const steps = [
      t('ui_spear.ticketDetails.TicketJourney.Ticket_Created'),
      t('ui_spear.ticketDetails.TicketJourney.In_Approval'),
      t('ui_spear.ticketDetails.TicketJourney.In_Progress'),
      t('ui_spear.ticketDetails.TicketJourney.Ticket_Closed')
    ];
    if (props.TicketJourneyPage.status === 'rejected') {
      steps.splice(
        1,
        1,
        t('ui_spear.ticketDetails.TicketJourney.Something_Wrong')
      );
    }
    if (props.source === 'smt') {
      if (
        props.TicketJourneyPage.status === 'Pending' ||
        props.TicketJourneyPage.status === 'pending'
      ) {
        steps.splice(
          1,
          1,
          t('ui_spear.ticketDetails.TicketJourney.In_Progress')
        );
        steps.splice(2, 1, t('ui_spear.myTickets.Pending'));
      } else {
        steps.splice(1, 1);
        switch (statusLevel) {
          case 3:
            bulletPositionProgress = 2;
            break;
          case 4:
            bulletPositionProgress = 3;
            break;
          default:
            break;
        }
      }
    }
    return steps.map((step, i) => {
      return (
        <li
          key={step}
          className={`relative w-full step ${i === 0
            ? 'w-full step step-primary after:bg-support_banner_color_1 before:h-0 before:w-0'
            : 'before:h-1'
            } ${bulletPositionProgress > i
              ? `${activeProgressStep}`
              : `${inactiveProgressStep}`
            }`}
        >
          {bulletPositionProgress === i + 1 && (
            <div className="h-4 w-4 border bg-white rounded-full absolute top-4 z-10"></div>
          )}
          <div className="h-full flex flex-col">
            <span
              className={`${bulletPositionProgress === i + 1
                ? 'font-bold'
                : `${bulletPositionProgress - 1 < i ? 'opacity-40' : ''}`
                }`}
            >
              {step}
            </span>
            <span className="opacity-40">
              {i === 0
                ? props.source === 'smt'
                  ? formatedDate(
                    props.TicketJourneyPage.originalSubmittedDate,
                    false
                  )
                  : formatedDateWithOutTime(props.TicketJourneyPage.submittedDate)
                : handleDatesProgress(step)}
            </span>
          </div>
        </li>
      );
    });
  };

  const arrowPosition = () => {
    const smtStatusPending =
      props.TicketJourneyPage.status === 'Pending' ||
      props.TicketJourneyPage.status === 'pending';
    let arrowInProgressSMT = null;
    if (props.source === 'smt' && !smtStatusPending && statusLevel === 3) {
      arrowInProgressSMT = 590;
    }

    switch (bulletPositionProgress) {
      case 1:
        return props.source === 'smt' ? (smtStatusPending ? 20 : 85) : 20;
      case 2:
        return props.source === 'smt' ? (smtStatusPending ? 400 : 590) : 400;
      case 3:
        return props.source === 'smt'
          ? smtStatusPending
            ? 785
            : arrowInProgressSMT
          : 785;
      case 4:
        return props.source === 'smt' ? (smtStatusPending ? 1165 : 1100) : 1165;
      default:
        return 20;
    }
  };

  const TicketStatusWithArrowContainer = styled('div')({
    position: 'relative',
    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: '20px solid rgb(203 213 225)',
      borderBottom: '20px solid transparent',
      bottom: -40,
      left: arrowPosition()
    },
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: '20px solid #fff',
      borderBottom: '7px solid transparent',
      bottom: -26,
      left: arrowPosition()
    }
  });

  const scrollToFooter = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  const pendingStatusRenderMessage = () => {
    const submittedDate = new Date(
      formatedDate(props.TicketJourneyPage.originalSubmittedDate, false)
    );
    if (props.TicketJourneyPage.statusReason[0] == 'Client Hold') {
      const newDate = formatedDate(
        new Date(submittedDate.setDate(submittedDate.getDate() + 21)),
        true
      );
      return (
        <Fragment>
          <p style={{ fontWeight: 'bold' }}>{`${t(
            'ui_spear.ticketDetails.TicketJourney.We_held_processing'
          )} ${newDate} ${t(
            'ui_spear.ticketDetails.TicketJourney.on_your_demand'
          )}`}</p>
          <p>{t('ui_spear.ticketDetails.TicketJourney.After_this_date')}</p>
          <StledSuggestedButton
            onClick={() => {
              scrollToFooter();
            }}
          >
            {t('ui_spear.ticketDetails.Comments.Contact_us')}
          </StledSuggestedButton>
        </Fragment>
      );
    } else if (
      props.TicketJourneyPage.statusReason[0] == 'Client Action Required'
    ) {
      const newDate = formatedDate(
        new Date(submittedDate.setDate(submittedDate.getDate() + 5)),
        true
      );
      return (
        <Fragment>
          <p style={{ fontWeight: 'bold' }}>
            {t('ui_spear.ticketDetails.TicketJourney.Pending_Header')}
          </p>
          <p>{`${t(
            'ui_spear.ticketDetails.TicketJourney.Pending_Message_1'
          )} ${newDate} ${t(
            'ui_spear.ticketDetails.TicketJourney.Pending_Message_2'
          )}`}</p>
          <StledSuggestedButton
            onClick={() => {
              scrollToFooter();
            }}
          >
            {t('ui_spear.ticketDetails.Comments.Contact_us')}
          </StledSuggestedButton>
        </Fragment>
      );
    }
  };

  const messageStatus = () => {
    if (
      props.TicketJourneyPage.status == 'Pending' ||
      props.TicketJourneyPage.status == 'pending'
    ) {
      return pendingStatusRenderMessage();
    } else if (
      props.TicketJourneyPage.status == 'rejected' ||
      props.TicketJourneyPage.status == 'Rejected'
    ) {
      return `${t('ui_spear.ticketDetails.Your_request_was_rejected_by')} ${props.TicketJourneyPage.performer
        } ${performerDept}`;
    } else if (
      props.TicketJourneyPage.status == 'in_progress' ||
      props.TicketJourneyPage.status == 'in progress' ||
      props.TicketJourneyPage.status == 'In Progress'
    ) {
      return `${t('ui_spear.ticketDetails.Expert_is_working_on_your_ticket')}`;
    } else if (
      props.TicketJourneyPage.status == 'closed' ||
      props.TicketJourneyPage.status == 'completed'
    ) {
      return `${t('ui_spear.ticketDetails.Request_was_realized')}`;
    } else if (props.TicketJourneyPage.status == 'cancelled') {
      return `${t('ui_spear.ticketDetails.Your_request_was_cancelled')}`;
    } else {
      return statusAndPerformer;
    }
  };
  return (
    <div className="w-full">
      <div className="w-[1227px] max-w-full mx-auto">
        <TicketStatusWithArrowContainer className="my-5 py-10 border border-slate-300 flex items-baseline">
          <span
            className={`${statusIcon} text text-[35px]`}
            style={{
              position: 'relative',
              top: '6px',
              paddingLeft: '20px',
              marginRight: '5px',
              color:
                props.TicketJourneyPage.status == 'Pending' ||
                  props.TicketJourneyPage.status == 'pending'
                  ? 'rgb(255, 153, 51)'
                  : '#000000'
            }}
          ></span>
          <div className="flex flex-col justify-between gap-4">
            <p
              style={{
                color:
                  props.TicketJourneyPage.status == 'Pending' ||
                    props.TicketJourneyPage.status == 'pending'
                    ? 'rgb(255, 153, 51)'
                    : '#000000'
              }}
              className="text-3xl"
            >
              {statusLabel}
            </p>
            <p className="text-x2 text-gray-400 font-semibold">
              {props.source === 'smt'
                ? formatedDate(
                  props.TicketJourneyPage.originalLastDateTime,
                  true
                )
                : formatedDate(props.TicketJourneyPage.lastDateTime, true)}
            </p>
            {props.source === 'smt' &&
              props.TicketJourneyPage.status === 'Closed' &&
              props.TicketJourneyPage.resolution ? (
              <p className="font-thin text-bases">
                {t('ui_spear.ticketDetails.Your_request_was_closed')}
              </p>
            ) : (
              <p
                className="font-thin text-bases"
                dangerouslySetInnerHTML={{ __html: statusMessage }}
              ></p>
            )}
            {props.source === 'smt' &&
              props.TicketJourneyPage.status === 'Resolved' &&
              props.TicketJourneyPage.resolution ? (
              <table className="solution-table">
                <tbody>
                  <tr>
                    <td className="pl-5 pr-4">
                      {t('ui_spear.ticketDetails.Resolution')}:
                    </td>
                    <td className="w-screen">
                      {props.TicketJourneyPage.resolution}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              true
            )}
            {
              props.source === 'new' &&
                props.TicketJourneyPage.status === 'Completed' &&
                props.TicketJourneyPage.of_Resolution != null ? ( // Checks for null or undefined
                <table className="solution-table">
                  <tbody>
                    <tr>
                      <td className="pl-5 pr-4">
                        {t('ui_spear.ticketDetails.Resolution')}:
                      </td>
                      <td className="w-screen">
                        {props.TicketJourneyPage.of_Resolution}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null
            }
          </div>
        </TicketStatusWithArrowContainer>
      </div>

      <div className="pb-10 pt-2.5 w-full flex justify-center">
        <ul className="steps min-w-[1527px] !m-0 steps-vertical lg:steps-horizontal">
          {renderProgress()}
        </ul>
      </div>
    </div>
  );
};

export default TicketJourney;
